import React from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box, Text, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import NumbersGroup from "../components/blocks/numbersGroup"
import BackgroundImageAndBlock from "../components/blocks/backgroundImageAndBlock"
import ImageAndTextCollection from "../components/blocks/imageAndTextCollection"
import ItemCarousel from "../components/blocks/itemCarousel"
import HomeAnimation from "../components/homeAnimation"
import ContactFrom from "../components/blocks/contactFrom"
import VerticalLines from "../components/verticalLins"
import { MagicLink } from "../utils/magicLink"
import ArrowLong from "../images/long-arrow.inline.svg"
import { GatsbyImage } from "gatsby-plugin-image"

const Home = ({ data: { page, site, contactSection } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  return (
    <Layout page={page} locale={page.locale} i18nPaths={i18nPaths} home={true}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>

      {/* <VerticalLines /> */}

      {page.content.map((block, index) => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "numbers_group" && (
            <NumbersGroup
              numbers={block.numbers}
              content={block.numberparagrah}
            />
          )}
          {block.model.apiKey === "background_image_and_block" && (
            <>
              <BackgroundImageAndBlock
                content={block.blocks}
                align={block.align}
                color={block.color}
              />
            </>
          )}
          {block.model.apiKey === "item_carousel" && (
            <>
              <ItemCarousel items={block.items} />
            </>
          )}
          {block.model.apiKey === "full_image_title" && (
            <>
              <Container variant="customFW">
              {block.title &&
                <Heading
                  variant="h3"
                  as="div"
                  sx={{
                    h2: {
                      fontSize: [4, 5],
                      fontWeight: "normal",
                    },
                    color: "secondary",
                  }}
                  dangerouslySetInnerHTML={{ __html: block.title }}
                />}
                <Box
                  sx={{
                    position: ["relative", "relative", "relative"],
                    width: ["100%", "100%", "100%"],
                    top: 0,
                    ".gatsby-image-wrapper": {
                      width: "100%",
                      img: {
                        height:"100%",
                        width:"100%",
                        objectFit:"contain!important"
                      }
                    },
                  }}
                >
                  <GatsbyImage image={block.image.gatsbyImageData} alt="" />
                </Box>
              </Container>
            </>
          )}
          {block.model.apiKey === "image_and_text_collection" && (
            <>
              <ImageAndTextCollection
                items={block.content}
                smallImage={block.smallImage}
                align={block.align}
              />
            </>
          )}
        </Box>
      ))}
      {contactSection && (
        <>
          <Container
            name="contactForm"
            sx={{ py: [4, 4], position: "relative" }}
          >
            <Box>
              <Flex
                sx={{ justifyContent: "center", flexWrap: "wrap" }}
                gap={[1, 1, 1, 6]}
              >
                <Box sx={{ maxWidth: "600px" }}>
                  <Text variant="kicker" sx={{ color: "primary" }}>
                    {contactSection.nodes[0].kicker}
                  </Text>
                  <Heading
                    as="h2"
                    variant="h3"
                    sx={{
                      my: [3, 4],
                      color: "primary",
                      fontSize: [4, 4],
                      fontFamily: "body",
                      fontWeight: "300",
                      letterSpacing: ".5px",
                      lineHeight: "1.2",
                    }}
                  >
                    {contactSection.nodes[0].title}
                  </Heading>
                  <Heading
                    as="h3"
                    variant="h3"
                    sx={{
                      color: "primary",
                      fontWeight: 300,
                      my: [0, 4],
                    }}
                  >
                    {contactSection.nodes[0].subtitle}
                  </Heading>
                  <Box className="ContactFrom" sx={{ textAlign: "right" }}>
                    {contactSection.nodes[0].link && (
                      <MagicLink
                        item={contactSection.nodes[0].link}
                        variant="links.primary"
                      >
                        <Flex sx={{ alignItems: "center" }}>
                          {contactSection.nodes[0].link.anchor}
                          <Flex
                            className="arrowIcon"
                            sx={{ ml: [3], overflow: "hidden" }}
                          >
                            <ArrowLong />
                          </Flex>
                        </Flex>
                      </MagicLink>
                    )}
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Container>
        </>
      )}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      productPage {
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
          }
        }
      }
      heroImage {
        url
      }
      content {
        ... on DatoCmsFullImageTitle{
          id
          title
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsNumbersGroup {
          id
          numbers {
            id
            legend
            float
            suffix
            prefix
          }
          numberparagrah: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsBackgroundImageAndBlock {
          id
          blocks: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          align
          color
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          model {
            apiKey
          }
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
        }
        ... on DatoCmsImageAndTextCollection {
          id
          smallImage
          align
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    contactSection: allDatoCmsContactSection(
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        kicker
        title
        subtitle
        privacyPolicyDescription
        newsletterDescription
        link {
          ... on DatoCmsInternalLink {
            id: originalId
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
        }
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
